/**
 * This file will load on the host page. It creates and appends the iframe
 * to the host page's document. It is responsible for communication between
 * the host and iframe. It also creates the Helpshift global object.
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created Dec 1, 2023
 * @module src/middleware.js
 */

import {JS_API_ERROR_MSG} from "constants/error";
import {HELPSHIFT_WIDGET_APIS} from "api";
import {isApiValid, getQueuedApisWithNames} from "helpers/api";
import {middlewareState} from "singleton/middlewareState";
import "./styles/main.css";

const INIT = "init";

// Get APIs that were queued in the embed script
const apiQueueWithNames = getQueuedApisWithNames();

const initFromQueue = apiQueueWithNames.find((api) => api.name === INIT);

// Strip "init" from the queue since it is a special API call, intended
// to load the HC and WC
middlewareState.updateState({
  apiQueue: apiQueueWithNames.filter((api) => api.name !== INIT).map((api) => api.fn)
});

/**
 * The global HelpshiftWidget function to handle the APIs. It relies on the
 * following invocation pattern.
 *
 * // Call the addMessage api
 * HelpshiftWidget ("addMessage", apiArguments)
 * where addMessage is the name of the API and apiArguments is the argument
 * that is further passed to the api call.
 *
 * The number of arguments passed to this function may vary depending on which
 * API is called. The API should throw exception(s) based on its requirements.
 * @returns - Value returned in Helpshift API.
 */
window.HelpshiftWidget = function(api, ...apiArguments) {
  if (typeof api !== "string") {
    // Throw an error back to the client if an API is not called
    throw new Error(JS_API_ERROR_MSG.NO_API_NAME);
  } else if (typeof HELPSHIFT_WIDGET_APIS[api] !== "function") {
    // Throw an error if the API is not supported
    throw new Error(JS_API_ERROR_MSG.API_NOT_SUPPORTED + " - " + api);
  }

  // the API is init, then directly call the API
  // Else queue the API in sequence and call them after SDK config is loaded
  // Note :- Allowing init API because it's the first API that will be called
  const {sdkHasLoaded} = middlewareState.getState();
  if (sdkHasLoaded || api === INIT) {
    // Call the Helpshift widget api with the arguments
    return HELPSHIFT_WIDGET_APIS[api].apply(null, apiArguments);
  } else if (isApiValid(api)) {
    // Queue the API, if it's valid
    const {apiQueue} = middlewareState.getState();
    const apiToQueue = HELPSHIFT_WIDGET_APIS[api].bind(null, ...apiArguments);

    middlewareState.updateState({
      apiQueue: apiQueue.concat(apiToQueue)
    });
  }
};

if (initFromQueue) {
  initFromQueue.fn();
}
