/**
 * Error constants
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created Jan 31, 2023
 * @module constants/error
 */

export const JS_API_ERROR_MSG = {
  NO_API_NAME: "API name is not passed with the Helpshift Widget call",
  API_NOT_SUPPORTED: "The API name passed with the Helpshift Widget call is not supported"
};
