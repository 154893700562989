/**
 * This file contains the logic for handling postMessage events from the
 * child main.html of hs widget which is parent of webchat
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 22, 2023
 * @module helpers/postMessage.js
 */

import {
  API_EVENTS,
  WEBCHAT_EVENTS,
  CUSTOMER_EXPOSED_EVENTS,
  HELPCENTER_EVENTS
} from "constants/event";
import {HS_WIDGET_DOMAIN} from "constants/build";
import {middlewareState} from "singleton/middlewareState";
import {callApiEventHandler, clearApiQueue, getHelpshiftConfig} from "helpers/api";
import {
  showLauncherButton,
  showWidgetIframe,
  updateWidgetCssVar,
  updateWidgetOptionsRelatedCssVars
} from "helpers/widgetCss";
import {renderUnreadCount, toggleWidgetIframe} from "helpers/iframe";
import {WIDGET_TYPE} from "constants/widget";
import {LAUNCHER_BTN_PRIMARY_COLOR_LIGHT_75, LAUNCHER_BTN_PRIMARY_COLOR} from "constants/widgetCss";
import browserUtils from "gunpowder/utils/browser";

const IS_MOBILE = browserUtils.isMobile();

/**
 * Handles whether HS widget should be opened in fullscreen mode or not
 *
 * @param {boolean} widgetIsFullscreen - If true, fullscreen config is passed by the customer
 * @param {boolean} hsWidgetIsOpenedInMobile - If true, HS widget is opened in mobile
 */
export const handleFullScreenConfig = (widgetIsFullscreen, hsWidgetIsOpenedInMobile) => {
  const hsWidgetIframe = document.querySelector("#hs-widget-iframe");

  if (!widgetIsFullscreen && !hsWidgetIsOpenedInMobile) {
    hsWidgetIframe.classList.add("hs-widget-iframe");
    hsWidgetIframe.classList.remove("hs-widget-iframe--fullscreen");
    return;
  }

  hsWidgetIframe.classList.remove("hs-widget-iframe");
  hsWidgetIframe.classList.add("hs-widget-iframe--fullscreen");
};

/**
 * Updates primary color related css vars
 *
 * @param {Object} data - Event data
 * @param {string} data.primaryColor - Primary color set on B&C page on agent dashboard
 */
const _updatePrimaryColorRelatedCssVars = (data) => {
  updateWidgetCssVar(LAUNCHER_BTN_PRIMARY_COLOR_LIGHT_75.cssVarName, `${data.primaryColor}bf`);
  updateWidgetCssVar(LAUNCHER_BTN_PRIMARY_COLOR.cssVarName, data.primaryColor);
};

/**
 * Returns postmessage event data for HC and WC events
 * @param {Object} eventData - Post message event data
 */
const _getEventInfo = (eventData) => {
  // For WC events
  if (eventData.type) {
    return {
      type: eventData.type,
      data: eventData.data
    };
  }

  // For HC events
  if (eventData.eventType) {
    return {
      type: eventData.eventType,
      data: eventData.config
    };
  }
};

export const onMessage = (event) => {
  if (event.origin !== HS_WIDGET_DOMAIN) {
    return;
  }

  let type, data;
  try {
    const eventData = JSON.parse(event.data);
    const eventInfo = _getEventInfo(eventData);

    type = eventInfo.type;
    data = eventInfo.data;
  } catch (exception) {
    // We are not handling any kind of exception if data parsing fails
  }

  switch (type) {
    case API_EVENTS.JS_LOADED:
      const {widgetOptions} = window.helpshiftWidgetConfig;

      middlewareState.updateState(window.helpshiftWidgetConfig);
      updateWidgetOptionsRelatedCssVars(widgetOptions);

      handleFullScreenConfig(widgetOptions?.fullScreen, IS_MOBILE);

      // send processed data to set in webchat config
      event.source.postMessage(
        JSON.stringify({
          type: API_EVENTS.SET_CONFIG,
          data: getHelpshiftConfig(window.helpshiftWidgetConfig)
        }),
        event.origin
      );
      break;

    case API_EVENTS.STORE_LS_DATA:
      {
        const {anonUserId, deviceId} = data;

        if (anonUserId) {
          middlewareState.updateState({
            anonUserId
          });
        }

        if (deviceId) {
          middlewareState.updateState({
            deviceId
          });
        }
      }
      break;

    case API_EVENTS.HS_WIDGET_TYPE_RESET:
      window.helpshiftWidgetConfig.widgetType = data.widgetType;
      break;

    case HELPCENTER_EVENTS.CLOSE:
    case WEBCHAT_EVENTS.SDK_EVENT_CLOSE_HELPSHIFT_WIDGET:
      {
        const {showLauncher} = middlewareState.getState().widgetOptions;
        showWidgetIframe(false);
        toggleWidgetIframe();
        showLauncherButton({launcherBtnShouldBeShown: true, showLauncher});
        renderUnreadCount();
      }
      break;

    case HELPCENTER_EVENTS.HELPCENTER_LOADED:
      {
        const {
          widgetOptions: {showLauncher},
          widgetType,
          apiQueue,
          sdkHasLoaded
        } = middlewareState.getState();

        if (!sdkHasLoaded) {
          if (widgetType === WIDGET_TYPE.HELPCENTER) {
            showLauncherButton({launcherBtnShouldBeShown: true, showLauncher});

            middlewareState.updateState({
              sdkHasLoaded: true
            });
            clearApiQueue(apiQueue);
          }

          _updatePrimaryColorRelatedCssVars({primaryColor: data.primaryColor});
        }
      }
      break;

    // SDK_CONFIG_LOADED corresponds to the event which gets triggered when Web Chat's
    // config has loaded. This is the internal event exposed from Web Chat
    case WEBCHAT_EVENTS.SDK_CONFIG_LOADED:
      {
        const {showLauncher, sdkHasLoaded} = middlewareState.getState().widgetOptions;

        if (!sdkHasLoaded) {
          showLauncherButton({launcherBtnShouldBeShown: true, showLauncher});

          const {apiQueue} = middlewareState.getState();
          middlewareState.updateState({
            sdkHasLoaded: true
          });
          clearApiQueue(apiQueue);
        }
      }
      break;

    case WEBCHAT_EVENTS.SDK_EVENT_CONVERSATION_RESOLVED:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.CONVERSATION_RESOLVED);
      break;

    case WEBCHAT_EVENTS.SDK_EVENT_CONVERSATION_START:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.CONVERSATION_START, {
        message: data.message
      });
      break;

    case WEBCHAT_EVENTS.SDK_EVENT_CONVERSATION_REJECTED:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.CONVERSATION_REJECTED);
      break;

    case WEBCHAT_EVENTS.SDK_EVENT_CONVERSATION_REOPENED:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.CONVERSATION_REOPENED);
      break;

    case WEBCHAT_EVENTS.SDK_EVENT_CSAT_SUBMIT:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.CSAT_SUBMIT, {
        rating: data.rating,
        additionalFeedback: data.review
      });
      break;

    case WEBCHAT_EVENTS.SDK_USER_CHANGED_VIA_RE_ENGAGEMENT:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.USER_CHANGED, data.userInfo);
      break;

    case WEBCHAT_EVENTS.SDK_EVENT_MESSAGE_ADD:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.MESSAGE_ADD, {
        type: data.type,
        body: data.body
      });
      break;

    case WEBCHAT_EVENTS.SDK_EVENT_CONVERSATION_END:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.CONVERSATION_END);
      break;

    case WEBCHAT_EVENTS.SDK_UPDATE_UNREAD_COUNT:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.NEW_UNREAD_MESSAGES, {
        unreadCount: data.count
      });

      middlewareState.updateState({
        unreadMsgCount: data.count
      });
      renderUnreadCount();
      break;

    case WEBCHAT_EVENTS.SDK_EVENT_UI_CONFIG_CHANGE:
      _updatePrimaryColorRelatedCssVars(data);

      break;

    case WEBCHAT_EVENTS.SDK_EVENT_SEND_GENERIC_SDK_DATA:
      callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.GENERIC_SDK_DATA, data);
      break;
  }
};
