/**
 * This file exports event names of postMessage
 * 1. communication between parent i.e index.html and hswidget i.e main.html
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 22, 2023
 * @module constants/build
 */

export const API_EVENTS = {
  JS_LOADED: "js-loaded",
  SET_CONFIG: "set-config",
  CALL_WEBCHAT_JS_API: "call-webchat-js-api",
  STORE_LS_DATA: "store-ls-data",
  UPDATE_HELPSHIFT_WIDGET_CONFIG: "update-helpshift-widget-config",
  // This event is used to reset widgetType in helpshiftWidgetConfig in case
  // customer try to update it along with updateHelpshiftWidgetConfig JS API
  HS_WIDGET_TYPE_RESET: "hs-widget-type-reset"
};

export const CUSTOMER_EXPOSED_EVENTS = {
  CONVERSATION_RESOLVED: "conversationResolved",
  CONVERSATION_START: "conversationStart",
  CONVERSATION_REJECTED: "conversationRejected",
  CONVERSATION_REOPENED: "conversationReopened",
  CSAT_SUBMIT: "csatSubmit",
  USER_CHANGED: "userChanged",
  MESSAGE_ADD: "messageAdd",
  CONVERSATION_END: "conversationEnd",
  NEW_UNREAD_MESSAGES: "newUnreadMessages",
  WIDGET_TOGGLE: "widgetToggle",
  GENERIC_SDK_DATA: "genericSdkData"
};

export const WEBCHAT_EVENTS = {
  SDK_EVENT_CONVERSATION_RESOLVED: "sdk-event-conversation-resolved",
  SDK_EVENT_CONVERSATION_START: "sdk-event-conversation-start",
  SDK_EVENT_CONVERSATION_REJECTED: "sdk-event-conversation-rejected",
  SDK_EVENT_CONVERSATION_REOPENED: "sdk-event-conversation-reopened",
  SDK_EVENT_CSAT_SUBMIT: "sdk-event-csat-submit",
  SDK_USER_CHANGED_VIA_RE_ENGAGEMENT: "sdk-user-changed-via-re-engagement",
  SDK_EVENT_MESSAGE_ADD: "sdk-event-message-add",
  SDK_EVENT_CONVERSATION_END: "sdk-event-conversation-end",
  SDK_UPDATE_UNREAD_COUNT: "sdk-update-unread-count",
  SDK_CONFIG_LOADED: "sdk-config-loaded",
  CMD_MESSENGER_TOGGLED: "cmd-messenger-toggled",
  SDK_EVENT_UI_CONFIG_CHANGE: "sdk-event-ui-config-change",
  SDK_EVENT_CLOSE_HELPSHIFT_WIDGET: "sdk-event-close-helpshift-widget",
  SDK_EVENT_SEND_GENERIC_SDK_DATA: "sdk-event-send-generic-sdk-data"
};

export const HELPCENTER_EVENTS = {
  CLOSE: "closeHelpcenter",
  HELPCENTER_LOADED: "helpcenterLoaded"
};
