/**
 * This file contains the helpers to create the iframe for hswidget
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 22, 2023
 * @module helpers/iframe.js
 */

import {LAUNCHER_HS_ICON_SVG} from "constants/icon";
import {middlewareState} from "singleton/middlewareState";
import {callApiEventHandler} from "helpers/api";
import {CUSTOMER_EXPOSED_EVENTS} from "constants/event";
import {trackEvent} from "helpers/analytics";

export const renderUnreadCount = () => {
  const {
    widgetOptions: {showLauncher}
  } = middlewareState.getState();

  if (!showLauncher) {
    return;
  }

  const launcherButtonUnreadCountEl = document.querySelector(
    ".hs-widget-launcher-button-unread-count"
  );

  if (!launcherButtonUnreadCountEl) {
    return;
  }

  const {unreadMsgCount, hsWidgetIframeIsVisible} = middlewareState.getState();

  if (unreadMsgCount && !hsWidgetIframeIsVisible) {
    launcherButtonUnreadCountEl.innerHTML = unreadMsgCount;
    launcherButtonUnreadCountEl.classList.remove("hide");
  } else {
    launcherButtonUnreadCountEl.innerHTML = "";
    launcherButtonUnreadCountEl.classList.add("hide");
  }
};

/**
 * This function creates and returns hs widget iframe
 * @param {string} hsWidgetUrl
 * @returns {HTMLElement} iframe
 */
export const createHsWidgetIframe = (hsWidgetUrl) => {
  const iframe = document.createElement("iframe");
  iframe.setAttribute("role", "none");
  iframe.id = "hs-widget-iframe";
  iframe.classList.add("hs-widget-iframe", "hide");
  iframe.src = hsWidgetUrl;

  return iframe;
};

/**
 * Creates and returns launcher button for hs widget
 * @returns {HTMLElement}
 */
export const createHsWidgetLauncherButton = () => {
  const launcherButton = document.createElement("button");
  launcherButton.id = "hs-widget-laucher-button";
  launcherButton.classList.add("hs-widget-laucher-button");

  const launcherIconEl = document.createElement("span");
  launcherIconEl.innerHTML = LAUNCHER_HS_ICON_SVG;
  launcherButton.appendChild(launcherIconEl);

  return launcherButton;
};

/**
 * Creates and returns launcher button for hs widget
 * @returns {HTMLElement}
 */
export const createHsWidgetWrapper = () => {
  const widgetWrapper = document.createElement("div");
  widgetWrapper.id = "hs-widget-wrapper";

  return widgetWrapper;
};

/**
 * Returns hs widget iframe element
 *
 * @returns {Element} - hs widget iframe element
 */
export const getHsWidgetIframeEl = () => {
  return document.getElementById("hs-widget-iframe");
};

export const toggleWidgetIframe = () => {
  const {hsWidgetIframeIsVisible, widgetType} = middlewareState.getState();

  if (hsWidgetIframeIsVisible) {
    trackEvent.widgetMaximize({wt: widgetType});
  } else {
    trackEvent.widgetMinimize({wt: widgetType});
  }

  callApiEventHandler(CUSTOMER_EXPOSED_EVENTS.WIDGET_TOGGLE, {
    visible: hsWidgetIframeIsVisible
  });
};
