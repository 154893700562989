/**
 * Routes constants.
 * @author Umang Govil <umang@helpshift.com>
 * @created April 30, 2024
 * @module constants/route
 */

/**
 * Returns tracking xhr route for analytics events
 *
 * @param {string} domain - Domain name
 * @returns {string} - Tracking xhr route for analytics events
 */
export const postAnalyticsXhrRoute = (domain) => {
  return `https://api.helpshift.com/events/v1/${domain}/websdk/`;
};
