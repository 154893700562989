"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var web_sdk_analytics_1 = require("@scopely/web-sdk-analytics");
var web_sdk_analytics_standard= require("@scopely/standard-events");


// QA API Key
var apiKey = '7d982f37-013a-4d75-b852-1c0b239f2c52';
// appName and locale
var appName = 'com.gsn.gsncasino';
var platform = 'webstore';
var locale = 'EN_US';
// Set the build type to production if the NODE_ENV is production
var buildType = process.env.NODE_ENV === 'production' ? web_sdk_analytics_1.BuildType.Production : web_sdk_analytics_1.BuildType.Debug;
var trackerSettings = new web_sdk_analytics_1.Settings(apiKey, buildType);
var tracker = new web_sdk_analytics_1.BrowserTracker(trackerSettings);
tracker.register();

function Analytics() {
    const context = 'login from main view';
    const sysConnect = new web_sdk_analytics_standard.SysConnect(web_sdk_analytics_standard.SysConnectConnectionTypeEnum.ScopelyId, context);
    sysConnect.id = '1234567';

    tracker.track('sys.connect', {
        connection: 'ScopelyId',
        id: '1234567',
        context: context,
    });

    return "";
}
exports.default = Analytics;
