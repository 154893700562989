/**
 * This file exports constants that are derived from the variables
 * replaced during the build.
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 22, 2023
 * @module constants/build
 */

export const HS_WIDGET_ROOT = "https://hs-widget.helpshift.com";
export const VERSION = "1.6.0";
// the URL object
const url = new URL(HS_WIDGET_ROOT);
const {host, pathname, protocol} = url;
const INDEX_HTML_PATH = `${VERSION}/html/main.html`;
// A query string with parent page's origin, which is later used with the
// postMessage call by hs widget to the parent page.
const PARENT_ORIGIN_QUERY_STRING = `parent=${encodeURIComponent(window.location.origin)}`;

export const HS_WIDGET_DOMAIN = `${protocol}//${host}`;
export const HS_WIDGET_URL =
  `${protocol}//${host}${pathname}${INDEX_HTML_PATH}` + `?${PARENT_ORIGIN_QUERY_STRING}`;
