/**
 * Object utils
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created Feb 27, 2024
 * @module utils/object
 */

/**
 * Deep clones an object
 * Ref: https://stackoverflow.com/a/34624648
 *
 * @param {Object} obj - Object to be deep cloned
 * @returns {Object} - cloned object
 */
export const cloneDeep = (obj) => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  const copy = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = cloneDeep(obj[key]);
    }
  }

  return copy;
};

/**
 * A method to verify if a given value is an object.
 * Returns false if it's primitive, array or function.
 */
export const isObject = function(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
};
