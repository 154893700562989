export const WIDGET_POSITIONS = {
  TOP_LEFT: "top-left",
  TOP_RIGHT: "top-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right"
};

export const WIDGET_TYPE = {
  HELPCENTER_AND_WEBCHAT: "helpcenter_and_webchat",
  HELPCENTER: "helpcenter",
  WEBCHAT: "webchat"
};

export const LAUNCHER_DEFAULT_Z_INDEX = 9999991;
