/**
 * Analytics related helpers
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created March 28, 2024
 * @module helpers/analytics
 */

import {PAYLOAD_EVENT, PAYLOAD_DATA} from "constants/analytics";
import {middlewareState} from "singleton/middlewareState";
import browserUtils from "gunpowder/utils/browser";
import {getCommonHeaders, getPreparedXhrData} from "helpers/xhr";
import objectUtils from "gunpowder/utils/object";
import xhr from "gunpowder/utils/xhr";
import {postAnalyticsXhrRoute} from "constants/routes";

const _getDefaultPayload = () => {
  const {deviceId, language: developerSetLanguage} = middlewareState.getState();
  const language = browserUtils.getLanguage();

  const payload = {
    [PAYLOAD_DATA.ID]: deviceId,
    [PAYLOAD_DATA.TIMESTAMP]: Date.now(), // Timestamp of when the event is tracked
    [PAYLOAD_DATA.LANGUAGE]: language,
    [PAYLOAD_DATA.HS_WIDGET_VERSION]: "1.6.0",
    [PAYLOAD_DATA.WIDGET_PLATFORM]: "web" // default platform for widget is web
  };

  if (developerSetLanguage) {
    payload[PAYLOAD_DATA.DEV_SET_LANGUAGE] = developerSetLanguage;
  }

  return getPreparedXhrData(payload);
};

/**
 * Fire the XHR to track the given events payload.
 * @param {Object[]} eventsPayload - Array of events payload with name, event timestamp etc.
 */
const _fireTrackingXhr = (eventPayload) => {
  const {domain, pcWidgetConfig} = middlewareState.getState();
  const defaultPayload = _getDefaultPayload();
  const eventData = {
    e: JSON.stringify(eventPayload)
  };

  if (pcWidgetConfig && Object.keys(pcWidgetConfig).length) {
    eventData[PAYLOAD_DATA.PLUGIN_TYPE] = pcWidgetConfig.pluginType;
    eventData[PAYLOAD_DATA.PLUGIN_VERSION] = pcWidgetConfig.pluginVersion;
    eventData[PAYLOAD_DATA.WIDGET_PLATFORM] = pcWidgetConfig.widgetPlatform;
  }

  const data = objectUtils.shallowMerge(defaultPayload, eventData);

  xhr({
    route: postAnalyticsXhrRoute(domain),
    method: "POST",
    headers: getCommonHeaders(),
    data
  });
};

/**
 * Track the hs widget maximize event. This event is tracked when widget is maximized
 * @param {Object} data - Event data
 */
const _trackWidgetMaximizeEvent = (data) => {
  _fireTrackingXhr([
    {
      t: PAYLOAD_EVENT.MAXIMIZE,
      ts: Date.now(),
      d: data
    }
  ]);
};

/**
 * Track the hs widget minimize event. This event is tracked when widget is minimized
 * @param {Object} data - Event data
 */
const _trackWidgetMinimizeEvent = (data) => {
  _fireTrackingXhr([
    {
      t: PAYLOAD_EVENT.MINIMIZE,
      ts: Date.now(),
      d: data
    }
  ]);
};
export const trackEvent = {
  widgetMaximize: _trackWidgetMaximizeEvent,
  widgetMinimize: _trackWidgetMinimizeEvent
};
