/**
 * Analytics related constants
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created March 28, 2024
 * @module constants/analytics
 */

export const PAYLOAD_EVENT = {
  MAXIMIZE: "max",
  MINIMIZE: "min"
};

export const PAYLOAD_DATA = {
  ID: "id",
  TIMESTAMP: "timestamp",
  LANGUAGE: "ln",
  DEV_SET_LANGUAGE: "dln",
  HS_WIDGET_VERSION: "hwv",
  PLUGIN_TYPE: "s", // type of pc widget wrapper plugin (e.g. "unity", "unreal")
  PLUGIN_VERSION: "pv", // version of pc widget wrapper plugin
  WIDGET_PLATFORM: "wplat" // platform of widget (e.g. "web", "windows", "mac")
};
