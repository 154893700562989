/**
 * This file contains the helpers for performing operations on css variables used on
 * parent page.
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 22, 2023
 * @module helpers/widgetCss.js
 */

import {
  IFRAME_POSITION_CONFIG,
  LAUNCHER_BTN_POSITION_CONFIG,
  Z_INDEX_CONFIG,
  SHOW_LAUNCHER_CONFIG
} from "constants/widgetCss";
import {middlewareState} from "singleton/middlewareState";
import {getHsWidgetIframeEl, renderUnreadCount} from "helpers/iframe";

/**
 * Checks whether the hs widget launcher button should be visible or not
 *
 * @param {Object} config - config options
 * @param {boolean} config.launcherBtnShouldBeShown -  true if launcher button should be displayed
 * @param {boolean} config.showLauncher -  true if showLauncher is passed as true in the
 * @returns {boolean} - whether the hs widget launcher button should be visible or not
 */
const _shouldHsWidgetLauncherButtonBeVisible = ({launcherBtnShouldBeShown, showLauncher}) => {
  if (launcherBtnShouldBeShown) {
    return typeof showLauncher === "boolean" ? showLauncher : launcherBtnShouldBeShown;
  }

  return launcherBtnShouldBeShown;
};

/**
 * Sets a css variable on helpshift widget wrapper
 * @param {string} cssVarName - name of variable
 * @param {string} value - value to be set
 */
export const updateWidgetCssVar = (cssVarName, value) => {
  document.getElementById("hs-widget-wrapper").style.setProperty(cssVarName, value);
};

/**
 * Updates the position of the hs widget as a whole, including button, iframe.
 * @param {string} position - position of widget
 */
const _updateWidgetPosition = (position) => {
  updateWidgetCssVar(IFRAME_POSITION_CONFIG.cssVarName, IFRAME_POSITION_CONFIG[position]);
  updateWidgetCssVar(
    LAUNCHER_BTN_POSITION_CONFIG.cssVarName,
    LAUNCHER_BTN_POSITION_CONFIG[position]
  );
};

/**
 * Updates the css vars for making the hs widget dynamic
 * @param {object} widgetOptions - contains widget config options passed by developer
 */
export const updateWidgetOptionsRelatedCssVars = (widgetOptions) => {
  // @TODO: feature/hs-widget-demo:  Add validation for the all the config passed by the customer.
  if (widgetOptions?.position) {
    _updateWidgetPosition(widgetOptions.position);
  }
  if (widgetOptions?.zIndex) {
    updateWidgetCssVar(Z_INDEX_CONFIG.cssVarName, widgetOptions.zIndex);
  }
};

/**
 * This function controls the display of the launcher button
 * @param {Object} config - config options
 * @param {boolean} config.hsWidgetLauncherButtonShouldBeVisible -  true if launcher button
 * should be displayed
 * @param {boolean} config.showLauncher -  true if showLauncher is passed as true in the
 * developer config
 */
export const showLauncherButton = ({launcherBtnShouldBeShown, showLauncher}) => {
  const {hsWidgetIframeIsVisible} = middlewareState.getState();
  const hsWidgetLauncherButtonShouldBeVisible = _shouldHsWidgetLauncherButtonBeVisible({
    launcherBtnShouldBeShown,
    showLauncher
  });

  if (hsWidgetLauncherButtonShouldBeVisible && hsWidgetIframeIsVisible) {
    return;
  }

  middlewareState.updateState({
    hsWidgetLauncherButtonIsVisible: hsWidgetLauncherButtonShouldBeVisible
  });

  updateWidgetCssVar(
    SHOW_LAUNCHER_CONFIG.cssVarName,
    SHOW_LAUNCHER_CONFIG[String(hsWidgetLauncherButtonShouldBeVisible)]
  );
  renderUnreadCount();
};

/**
 * This function controls the display of the widget iframe
 * @param {boolean} widgetIframeShouldBeShown -  true if widget iframe should be displayed
 */
export const showWidgetIframe = (widgetIframeShouldBeShown) => {
  const hsWidgetIframe = getHsWidgetIframeEl();

  if (!widgetIframeShouldBeShown) {
    hsWidgetIframe.classList.add("hide");
    middlewareState.updateState({
      hsWidgetIframeIsVisible: false
    });

    return;
  }

  hsWidgetIframe.classList.remove("hide");
  middlewareState.updateState({
    hsWidgetIframeIsVisible: true,
    hsWidgetWasOpened: widgetIframeShouldBeShown
  });
};

/**
 * This function controls the display of unread count element in hs widget
 * @param {boolean} show -  true if unread count element should be displayed
 */
export const showUnreadCountEl = (show) => {
  const unreadCountEl = document.querySelector(".hs-widget-launcher-button-unread-count");

  if (show) {
    unreadCountEl.classList.remove("hide");
  } else {
    unreadCountEl.classList.add("hide");
  }
};
