/**
 * This file exports svg icons
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 22, 2023
 * @module constants/build
 */

export const LAUNCHER_HS_ICON_SVG = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
viewBox="0 0 360 360" fill="none">
<path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" d="M92 93C85.3726
93 80 98.3726 80 105V220C80 226.627 85.3726 232 92 232H207C213.627 232 219
226.627 219 220V105C219 98.3726 213.627 93 207 93H92ZM113 125C110.791 125
109 126.791 109 129C109 131.209 110.791 133 113 133H185C187.209 133 189
131.209 189 129C189 126.791 187.209 125 185 125H113ZM109 159C109 156.791
110.791 155 113 155H185C187.209 155 189 156.791 189 159C189 161.209 187.209
163 185 163H113C110.791 163 109 161.209 109 159ZM113 185C110.791 185 109
186.791 109 189C109 191.209 110.791 193 113 193H185C187.209 193 189 191.209
189 189C189 186.791 187.209 185 185 185H113Z" fill="white"/>
<path d="M153 140C146.373 140 141 145.373 141 152V255C141 261.627 146.373
267 153 267H254L264.611 277.92L280 293V152C280 145.373 274.627 140 268 140H153Z"
fill="white"/>
<path d="M184.5 186C178.977 186 174.5 190.477 174.5 196C174.5 201.523 178.977
206 184.5 206C190.023 206 194.5 201.523 194.5 196C194.5 190.477 190.023 186 184.5
186Z" fill="var(--hs-widget-launcher-btn-primary-color-light-75)"/>
<path d="M236.5 186C230.977 186 226.5 190.477 226.5 196C226.5 201.523 230.977
206 236.5 206C242.023 206 246.5 201.523 246.5 196C246.5 190.477 242.023 186 236.5 186Z"
fill="var(--hs-widget-launcher-btn-primary-color-light-75)"/>
</svg>`;
