/**
 * This file exports the State class
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 22, 2023
 * @module singleton/state.js
 */

import {cloneDeep} from "utils/object";
export class State {
  constructor(initialState) {
    this.state = initialState;
  }

  /**
   * Returns the cloned copy of state
   * @returns {Object} - state of the middleware
   */
  getState() {
    return cloneDeep(this.state);
  }

  /**
   * Partially updates the state object
   * @param {Object} - partial state object
   */
  updateState(newState) {
    this.state = {...this.state, ...newState};
  }

  /**
   * Sets the middleware state
   * @param {Object} newState
   */
  setState(newState) {
    this.state = newState;
  }
}
