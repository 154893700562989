/**
 * This file will load on the host page. It is responsible for determining, which version
 * of Helpshift widget to load, and load a versioned middleware file, responsible for the further
 * loading of the Helpshift widget iframe.
 * This dynamic tag "injection" will allow us to implement instant rollbacks, smoother canary
 * releases, and increase the stability of Helpshift widget
 * Ref: Helpshift widget arch doc - https://helpshift.atlassian.net/l/cp/dV5z611P
 *
 * @author Umang <umang@helpshift.com>
 * @created Dec 1, 2023
 * @module src/init.js
 */

(function() {
  /**
   * Loads a JavaScript file and attaches it to the body
   * @param {string} src - The file to load
   * @param {object} callbacks - Callbacks object
   * @param {Function} [callbacks.onerror] - The callback if the script can't load
   * @param {Function} [callbacks.onload] - The callback if the script loads properly
   */
  const _loadScript = (src, callbacks) => {
    const scriptEle = document.createElement("script");
    scriptEle.setAttribute("src", src);
    scriptEle.setAttribute("type", "module");
    document.body.appendChild(scriptEle);

    const handleOnLoad = () => {
      if (typeof callbacks.onload === "function") {
        callbacks.onload();
      }

      removeEventListeners();
    };

    const handleOnError = (ev) => {
      if (typeof callbacks.onerror === "function") {
        callbacks.onerror(ev);
      }

      removeEventListeners();
    };

    const removeEventListeners = () => {
      scriptEle.removeEventListener("load", handleOnLoad);
      scriptEle.removeEventListener("error", handleOnError);
    };

    scriptEle.addEventListener("load", handleOnLoad);
    scriptEle.addEventListener("error", handleOnError);
  };

  /**
   * Loads the Chat bundle for a particular tag
   * @param {string} tag - Tag for which to load the entire Helpshift widget bundle
   */
  const _loadVersionedMiddlewareFile = (tag) => {
    _loadScript(`https://hs-widget.helpshift.com/${tag}/middleware.js`, {
      onerror: () => {
        // eslint-disable-next-line no-console
        console.error("There was an error loading the Helpshift Widget SDK.");
      }
    });
  };

  _loadVersionedMiddlewareFile("1.6.0");
})();
