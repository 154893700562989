/**
 * This file contains all the customer exposed JS APIs handling
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created Jan 31, 2023
 * @module api
 */

import {
  createHsWidgetIframe,
  createHsWidgetLauncherButton,
  createHsWidgetWrapper,
  getHsWidgetIframeEl,
  renderUnreadCount,
  toggleWidgetIframe
} from "helpers/iframe";
import {handleFullScreenConfig, onMessage} from "helpers/postMessage";
import {HS_WIDGET_URL} from "constants/build";
import {middlewareState} from "singleton/middlewareState";
import {callApiEventHandler, isEventSupported, resetRegisteredEvent} from "helpers/api";
import {
  showLauncherButton,
  showWidgetIframe,
  showUnreadCountEl,
  updateWidgetOptionsRelatedCssVars
} from "helpers/widgetCss";
import {API_EVENTS} from "constants/event";
import {API_NAMES} from "constants/api";
import browserUtils from "gunpowder/utils/browser";

const IS_MOBILE = browserUtils.isMobile();

const init = () => {
  const hsWidgetIframeExists = getHsWidgetIframeEl();

  if (hsWidgetIframeExists) {
    return;
  }

  const hsWidgetWrapper = createHsWidgetWrapper();

  const hsWidgetIframe = createHsWidgetIframe(HS_WIDGET_URL);
  hsWidgetWrapper.appendChild(hsWidgetIframe);

  const hsWidgetLauncherButton = createHsWidgetLauncherButton();
  hsWidgetWrapper.appendChild(hsWidgetLauncherButton);

  const launcherButtonUnreadCountEl = document.createElement("span");
  launcherButtonUnreadCountEl.classList.add("hs-widget-launcher-button-unread-count", "hide");
  hsWidgetWrapper.appendChild(launcherButtonUnreadCountEl);

  document.body.appendChild(hsWidgetWrapper);

  // @TODO: feature/hs-widget-demo: Add relevant remove event listener
  hsWidgetLauncherButton.addEventListener("click", () => {
    showWidgetIframe(true);
    toggleWidgetIframe();
    showLauncherButton({launcherBtnShouldBeShown: false});
    renderUnreadCount();
  });

  window.addEventListener("message", onMessage);
};

/**
 * JS API to add supported events
 * @param {string} eventName - name of event
 * @param {Function} eventHandler - event handler
 */
const addEventListener = (eventName, eventHandler) => {
  const {apiEvents, eventRegister} = middlewareState.getState();

  if (isEventSupported(eventName) && eventHandler) {
    middlewareState.updateState({
      apiEvents: [...apiEvents, {eventName, eventHandler}]
    });
  }

  // If event has already occured for the current event
  // then call the handler with the registered data.
  // Reset the event in register once the handler is called.
  const registeredEvent = eventRegister[eventName];
  if (registeredEvent && registeredEvent.eventHasOccured) {
    callApiEventHandler(eventName, registeredEvent.data);
    resetRegisteredEvent(eventName);
  }
};

/**
 * JS API to remove supported events
 * @param {String} eventName - name of event
 * @param {Function} eventHandler - event handler
 */
const removeEventListener = (eventName, eventHandler) => {
  // If event name is supported, remove that event
  if (isEventSupported(eventName) && eventHandler) {
    const {apiEvents} = middlewareState.getState();
    middlewareState.updateState({
      apiEvents: apiEvents.filter((apiEvent) => {
        return !(apiEvent.eventName === eventName && apiEvent.eventHandler === eventHandler);
      })
    });
  }
};

/**
 * JS API to set greeting message
 * @param {String} message - greeting message
 */
const setGreetingMessage = (message) => {
  const hsWidgetIframe = getHsWidgetIframeEl();

  hsWidgetIframe.contentWindow.postMessage(
    JSON.stringify({
      type: API_EVENTS.CALL_WEBCHAT_JS_API,
      data: {
        apiName: API_NAMES.SET_GREETING_MSG,
        apiArgs: message
      }
    }),
    HS_WIDGET_URL
  );
};

/**
 * JS API to update helpshift widget config
 */
const updateHelpshiftWidgetConfig = () => {
  const hsWidgetIframe = getHsWidgetIframeEl();
  const {widgetOptions} = window.helpshiftWidgetConfig;

  // Earlier we were not handling some widget config options api in our code
  // like (fullscreen, zIndex, position) whenever we call it
  // using "updateHelpshiftWidgetConfig" api.
  // So, now we have handled it here just after we call "updateHelpshiftWidgetConfig" api.
  updateWidgetOptionsRelatedCssVars(widgetOptions);
  handleFullScreenConfig(widgetOptions?.fullScreen, IS_MOBILE);

  hsWidgetIframe.contentWindow.postMessage(
    JSON.stringify({
      type: API_EVENTS.UPDATE_HELPSHIFT_WIDGET_CONFIG,
      data: window.helpshiftWidgetConfig
    }),
    HS_WIDGET_URL
  );
};

/**
 * JS API to open the HS widget
 */
const open = () => {
  const {hsWidgetIframeWasHidden, hsWidgetLauncherButtonWasHidden} = middlewareState.getState();

  if (hsWidgetIframeWasHidden || hsWidgetLauncherButtonWasHidden) {
    return;
  }

  // We are using the value from showLauncher from window.helpshiftWidgetConfig
  // and not from middlewareState because `HelpshiftWidget("open")` can be called
  // before the middlewareState is updated
  const {showLauncher} = window.helpshiftWidgetConfig?.widgetOptions || {};
  showWidgetIframe(true);
  toggleWidgetIframe();
  showLauncherButton({launcherBtnShouldBeShown: false, showLauncher});

  renderUnreadCount();
};

/**
 * JS API to close the HS widget
 */
const close = () => {
  const {hsWidgetIframeWasHidden, hsWidgetLauncherButtonWasHidden} = middlewareState.getState();
  if (hsWidgetIframeWasHidden || hsWidgetLauncherButtonWasHidden) {
    return;
  }

  // We are using the value from showLauncher from window.helpshiftWidgetConfig
  // and not from middlewareState because `HelpshiftWidget("close")` can be called
  // before the middlewareState is updated
  const {showLauncher} = window.helpshiftWidgetConfig?.widgetOptions || {};
  showWidgetIframe(false);
  toggleWidgetIframe();
  showLauncherButton({launcherBtnShouldBeShown: true, showLauncher});

  renderUnreadCount();
};

/**
 * JS API to show the HS widget
 */
const show = () => {
  const hsWidgetIframe = getHsWidgetIframeEl();

  if (!hsWidgetIframe) {
    return;
  }

  const {hsWidgetIframeWasHidden, hsWidgetLauncherButtonWasHidden} = middlewareState.getState();

  // Here `hsWidgetIframeWasHidden` & `hsWidgetLauncherButtonWasHidden` are used to know out of
  // iframe and launcher button, which was hidden earlier so that based on that iframe or
  // launcher button can be made visible
  if (hsWidgetIframeWasHidden) {
    showWidgetIframe(true);
  } else if (hsWidgetLauncherButtonWasHidden) {
    const {showLauncher} = window.helpshiftWidgetConfig?.widgetOptions || {};
    showLauncherButton({launcherBtnShouldBeShown: true, showLauncher});
  }

  renderUnreadCount();

  middlewareState.updateState({
    hsWidgetIframeWasHidden: false,
    hsWidgetLauncherButtonWasHidden: false
  });
};

/**
 * JS API to hide the HS widget
 */
const hide = () => {
  const hsWidgetIframe = getHsWidgetIframeEl();
  const {hsWidgetIframeIsVisible, hsWidgetLauncherButtonIsVisible} = middlewareState.getState();

  if (!hsWidgetIframe) {
    return;
  }

  if (hsWidgetLauncherButtonIsVisible) {
    showLauncherButton({launcherBtnShouldBeShown: false});
    middlewareState.updateState({
      hsWidgetLauncherButtonWasHidden: true
    });
  } else if (hsWidgetIframeIsVisible) {
    showWidgetIframe(false);
    middlewareState.updateState({
      hsWidgetIframeWasHidden: true
    });
  }

  showUnreadCountEl(false);
};

/**
 * JS API mentioned in embed code of HS widget to indicate if
 * window.HelpshiftWidget function is already defined in the global scope or not
 */
const update = () => {
  // TODO: feature/hsbug-2639 - Add the relevant log or error after confirming with Product
  return;
};

export const HELPSHIFT_WIDGET_APIS = {
  init,
  addEventListener,
  removeEventListener,
  setGreetingMessage,
  open,
  close,
  show,
  hide,
  updateHelpshiftWidgetConfig,
  update
};
