/**
 * This file contains css variable constants
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 22, 2023
 * @module constants/widgetCss.js
 */

import {WIDGET_POSITIONS} from "constants/widget";

export const IFRAME_POSITION_CONFIG = {
  cssVarName: "--hs-widget-iframe-pos",
  [WIDGET_POSITIONS.TOP_LEFT]: "24px auto auto 20px",
  [WIDGET_POSITIONS.TOP_RIGHT]: "24px 20px auto auto",
  [WIDGET_POSITIONS.BOTTOM_LEFT]: "auto auto 24px 20px",
  [WIDGET_POSITIONS.BOTTOM_RIGHT]: "auto 20px 24px auto"
};

export const LAUNCHER_BTN_POSITION_CONFIG = {
  cssVarName: "--hs-widget-btn-pos",
  [WIDGET_POSITIONS.TOP_LEFT]: "16px auto auto 20px",
  [WIDGET_POSITIONS.TOP_RIGHT]: "16px 20px auto auto",
  [WIDGET_POSITIONS.BOTTOM_LEFT]: "auto auto 16px 20px",
  [WIDGET_POSITIONS.BOTTOM_RIGHT]: "auto 20px 16px auto"
};

export const Z_INDEX_CONFIG = {
  cssVarName: "--hs-widget-z-index"
};

export const SHOW_LAUNCHER_CONFIG = {
  cssVarName: "--hs-widget-btn-display",
  true: "block",
  false: "none"
};

export const LAUNCHER_BTN_PRIMARY_COLOR_LIGHT_75 = {
  cssVarName: "--hs-widget-launcher-btn-primary-color-light-75"
};

export const LAUNCHER_BTN_PRIMARY_COLOR = {
  cssVarName: "--hs-widget-launcher-btn-primary-color"
};
