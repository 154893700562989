/**
 * This file exports the singleton for the middleware state, this is the state of the parent page.
 *
 * @author Rehan <rehan.shaikh@helpshift.com>
 * @created Jan 30, 2023
 * @module singleton/middlewareState.js
 */

import {State} from "singleton/state";
import {WIDGET_POSITIONS, LAUNCHER_DEFAULT_Z_INDEX} from "constants/widget";

export const middlewareState = new State({
  domain: "",
  platformId: "",
  language: "",
  appId: "",
  userId: "",
  userName: "",
  userEmail: "",
  userAuthToken: "",
  widgetOptions: {
    showLauncher: true,
    fullScreen: false,
    position: WIDGET_POSITIONS.BOTTOM_RIGHT,
    zIndex: LAUNCHER_DEFAULT_Z_INDEX
  },
  fullPrivacy: false,
  initiateChatOnLoad: false,
  // This contains the event's details like its data & whether that event has occured or not
  eventRegister: {},
  // This contains all APIs that the developer/customer calls using the HelpshiftWidget object
  apiEvents: [],
  initialUserMessage: "",
  cifs: {},
  tags: [],
  conversationPrefillText: "",
  clearAnonymousUserOnLogin: false,
  apiQueue: [],
  sdkHasLoaded: false,
  deviceId: "",
  anonUserId: "",
  hsWidgetIframeIsVisible: false,
  hsWidgetLauncherButtonIsVisible: false,
  hsWidgetIframeWasHidden: false,
  hsWidgetLauncherButtonWasHidden: false,
  unreadMsgCount: 0,
  pcWidgetConfig: {}
});
