/**
 * Api related constants
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created March 4, 2024
 * @module constants/api
 */

export const API_NAMES = {
  SET_GREETING_MSG: "setGreetingMessage"
};
