/**
 * XHR helpers
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created March 28, 2024
 * @module helpers/xhr
 */

import {middlewareState} from "singleton/middlewareState";
import browserUtils from "gunpowder/utils/browser";
import objectUtils from "gunpowder/utils/object";

const API_VERSION_HEADER = "application/vnd+hsapi-v2+json";

/**
 * Return common headers which are to be passed to each xhr request.
 * @returns {Object} - Headers for the network request
 */
export const getCommonHeaders = () => {
  const {platformId, language: developerSetLanguage} = middlewareState.getState();
  const language = browserUtils.getLanguage();

  const headers = {
    Accept: API_VERSION_HEADER
  };

  headers.authorization = "Basic " + btoa(platformId + ":");

  if (developerSetLanguage) {
    headers["Accept-Language"] = `${developerSetLanguage};q=1,${language};q=0.9`;
  }

  return headers;
};

/**
 * Get the data object to be passed with the `data` field of an XHR call. It
 * merges the custom data (specific to an XHR) with the common XHR data. The
 * common XHR data contains the following.
 * 1. Device ID (did)
 * 2. User identifier (uid)
 * 3. Email (email)
 * 4. User hash (user_auth_token)
 * 5. Platform ID (platform_id)
 *
 * @param {Object} [customXhrData]
 * @returns {String} - JSON stringified prepared data for the network call
 */
export const getPreparedXhrData = (customXhrData) => {
  const {
    deviceId,
    userId,
    anonUserId,
    userEmail,
    userAuthToken,
    platformId,
    fullPrivacy: fullPrivacyIsEnabled
  } = middlewareState.getState();
  let xhrData;

  const commonXhrData = {
    did: deviceId
  };

  // Set `uid` to the xhr data according to the following rules.
  // If none of userId, email and phoneNumber is passed, set `uid` to `anonUserIdentifier`.
  // If userId is passed (irrespective of if email is passed), set `uid` to `userId`.
  // If userId is not passed and email is passed, don't send `uid`.
  // If email is the only identifier in fullPrivacy mode, set `uid` to `anonUserIdentifier`.
  if ((!userId && !userEmail) || (!userId && userEmail && fullPrivacyIsEnabled)) {
    commonXhrData.uid = anonUserId;
  } else if (userId) {
    commonXhrData.uid = userId;
  }

  commonXhrData["platform-id"] = platformId;

  // Handle fullPrivacy mode and HMAC
  // If fullPrivacy is not enabled, send all (userId, userEmail, userAuthToken) the values.
  // If fullPrivacy is enabled then
  //    If email is not set and userId and userAuthToken are set, send userId and
  //    userAuthToken.
  //    If email is set, do not send userAuthToken
  if (!fullPrivacyIsEnabled) {
    if (userEmail) {
      commonXhrData.email = userEmail;
    }

    if (userAuthToken) {
      commonXhrData.user_auth_token = userAuthToken;
    }
  } else if (!userEmail && userId && userAuthToken) {
    commonXhrData.user_auth_token = userAuthToken;
  }

  // Merge custom and common XHR data objects if the passed custom data is
  // an object.
  if (objectUtils.isObject(customXhrData)) {
    xhrData = objectUtils.shallowMerge(commonXhrData, customXhrData);
  } else {
    xhrData = commonXhrData;
  }

  return xhrData;
};
