/**
 * This file contains all the customer exposed JS APIs handling along
 * with helper functions like queuing mechanism for the APIs etc.
 *
 * @author Umang Govil <umang@helpshift.com>
 * @created Jan 31, 2023
 * @module helpers/api
 */

import {HELPSHIFT_WIDGET_APIS} from "api";
import {middlewareState} from "singleton/middlewareState";
import {CUSTOMER_EXPOSED_EVENTS} from "constants/event";

/**
 * Check if the given API is supported
 * @param {string} api - the API name string
 * @returns {boolean}
 */
export const isApiValid = (api) => {
  return typeof HELPSHIFT_WIDGET_APIS[api] === "function";
};

/**
 * Get APIs queued with the global Helpshift function defined in the embed
 * script along with their names
 * @returns {Array} - List of functions for APIs bound with the arguments.
 */
export const getQueuedApisWithNames = () => {
  // The window.HelpshiftWidget function defined in the embed script contains a
  // static queue used to store the API calls made by client side JavaScript.
  // For each item of the queue, add an item (a function bound with the API's
  // arguments) to the list to return.
  const HS = window.HelpshiftWidget;
  const validApiQueue = [];

  if (HS && Array.isArray(HS.q) && HS.q.length) {
    HS.q.forEach((queuedArgs) => {
      // Convert arguments to an array.
      // For V8 optimization reasons, using a for loop here, instead of slicing
      // the arguments to make a new array.
      // For details, check the following -
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/arguments
      // https://github.com/petkaantonov/bluebird/wiki/Optimization-killers#32-leaking-arguments
      const queuedArgsLen = queuedArgs.length;
      const args = [];
      for (let i = 0; i < queuedArgsLen; i++) {
        args.push(queuedArgs[i]);
      }

      // The array args contains the API name ("open", "addEventListener", etc)
      // as the first item. Rest of the items of the args array are the arguments
      // that the API should execute with.
      const api = args[0];
      const apiArgs = args.slice(1);

      if (isApiValid(api)) {
        const apiFn = HELPSHIFT_WIDGET_APIS[api];
        // Concatenating apiArgs with null in order to specify the context of
        // the bound function (null).
        validApiQueue.push({fn: apiFn.bind(...[null].concat(apiArgs)), name: api});
      }
    });
  }

  return validApiQueue;
};

/**
 * Execute every queued api and clear the api queue
 * @param {Array} apiQueue - API queue
 *
 * @returns {Array} - Empty queue.
 */
export const clearApiQueue = (apiQueue) => {
  apiQueue.forEach((fn) => {
    if (typeof fn === "function") {
      fn();
    }
  });

  middlewareState.updateState({
    apiQueue: []
  });

  return [];
};

/**
 * Function to find the event name in the event list and call its handler.
 * If eventName is not found within event list then add it in eventRegister.
 * @param {string} eventName - Name of the event
 * @param {*} eventData - Data for the event
 */
export const callApiEventHandler = (eventName, eventData) => {
  const {apiEvents, eventRegister} = middlewareState.getState();

  const eventToBeCalled = apiEvents.find((apiEvent) => apiEvent.eventName === eventName);
  if (eventToBeCalled) {
    eventToBeCalled.eventHandler(eventData);

    return;
  }

  // Add event to the eventRegister if the handler is not found.
  // The event handler will be called when developer calls the
  // addEventListener Helpshift API for this event.
  const currentEventRegister = {...eventRegister};
  let cumulativeEventData;

  // If the event is already registered, then enqueue the data to
  // already registered event's data
  if (currentEventRegister[eventName] && currentEventRegister[eventName].data) {
    cumulativeEventData = {...currentEventRegister[eventName].data, ...eventData};
  } else {
    cumulativeEventData = eventData;
  }

  currentEventRegister[eventName] = {
    eventHasOccured: true,
    data: cumulativeEventData
  };

  middlewareState.updateState({eventRegister: currentEventRegister});
};

/**
 * Returns boolean if event name is supported
 * @param {string} eventName - name of event
 * @returns {boolean} - whether event name is supported
 */
export const isEventSupported = (eventName) => {
  return Object.values(CUSTOMER_EXPOSED_EVENTS).some(
    (currentEventName) => currentEventName === eventName
  );
};

/**
 * Function to get the default value for registered event
 * @returns {Object}
 */
const _getDefaultRegisteredEventValue = () => {
  return {
    eventHasOccured: false,
    data: null
  };
};

/**
 * Reset registered events value to default value.
 * @param {string} eventName
 */
export const resetRegisteredEvent = (eventName) => {
  const {eventRegister: currentEventRegister} = middlewareState.getState();

  currentEventRegister[eventName] = _getDefaultRegisteredEventValue();
  middlewareState.updateState({eventRegister: currentEventRegister});
};

/**
 * Returns helpshiftConfig config for Helpcenter & Web Chat JS context
 *
 * @param {Object} data - Data out of which helpshift config is being created
 * for Helpcenter & Web Chat
 * @returns {Object} - helpshiftConfig object
 */
export const getHelpshiftConfig = (data) => {
  const helpshiftConfig = {
    ...data,
    widgetSdkConfig: {
      widgetType: data.widgetType,
      version: "1.6.0",
      widgetPlatform: "web"
    },
    helpcenterConfig: {
      faqId: data.faqId,
      sectionId: data.sectionId
    }
  };

  if (typeof data?.widgetOptions?.fullScreen === "boolean") {
    helpshiftConfig.widgetSdkConfig.fullScreen = data.widgetOptions.fullScreen;
  }

  // For PC widget set the pluginVersion, widgetPlatform, pluginType
  if (data.pcWidgetConfig) {
    helpshiftConfig.widgetSdkConfig.pluginType = data.pcWidgetConfig?.pluginType;
    helpshiftConfig.widgetSdkConfig.pluginVersion = data.pcWidgetConfig?.pluginVersion;
    helpshiftConfig.widgetSdkConfig.widgetPlatform = data.pcWidgetConfig?.widgetPlatform;
  }

  // We need to delete these keys as the parent data object (data argument of this method)
  // comes from the developer config which has a different structure than that of
  // helpshiftConfig which is consumed by HC and WC
  const keysToBeDeletedFromHelpshiftConfig = ["widgetType", "faqId", "sectionId", "widgetOptions"];
  keysToBeDeletedFromHelpshiftConfig.forEach((keyToBeDeleted) => {
    delete helpshiftConfig[keyToBeDeleted];
  });

  return helpshiftConfig;
};
